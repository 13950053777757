import { default as _91channelUrl_93PaWlbsOAmoMeta } from "/builds/im2.0/aican/pages/chat/[channelUrl].vue?macro=true";
import { default as _403DifnDE22aeMeta } from "/builds/im2.0/aican/pages/errorPage/403.vue?macro=true";
import { default as _404EtPRYamu3zMeta } from "/builds/im2.0/aican/pages/errorPage/404.vue?macro=true";
import { default as forgetPwdFmaapHtMuNMeta } from "/builds/im2.0/aican/pages/forgetPwd.vue?macro=true";
import { default as conversationd5B72YIctPMeta } from "/builds/im2.0/aican/pages/index/conversation.vue?macro=true";
import { default as customerCenterBAMuPcySfzMeta } from "/builds/im2.0/aican/pages/index/customerCenter.vue?macro=true";
import { default as feedbackGhEQLGtRzyMeta } from "/builds/im2.0/aican/pages/index/feedback.vue?macro=true";
import { default as historyhaHMiGXUYQMeta } from "/builds/im2.0/aican/pages/index/history.vue?macro=true";
import { default as homeYyQLCzOL84Meta } from "/builds/im2.0/aican/pages/index/home.vue?macro=true";
import { default as profileG6dyZ4E2jaMeta } from "/builds/im2.0/aican/pages/index/profile.vue?macro=true";
import { default as assignoGWt5i2QJ8Meta } from "/builds/im2.0/aican/pages/index/setup/assign.vue?macro=true";
import { default as basicZTYi8Y1XjEMeta } from "/builds/im2.0/aican/pages/index/setup/basic.vue?macro=true";
import { default as blacklistNtcuQKhEEUMeta } from "/builds/im2.0/aican/pages/index/setup/blacklist.vue?macro=true";
import { default as conversationTag6ccwzCvafGMeta } from "/builds/im2.0/aican/pages/index/setup/conversationTag.vue?macro=true";
import { default as customerTagEKsj6m05O5Meta } from "/builds/im2.0/aican/pages/index/setup/customerTag.vue?macro=true";
import { default as queueZMmRiZnHJSMeta } from "/builds/im2.0/aican/pages/index/setup/queue.vue?macro=true";
import { default as quickReplyINA2Ha6960Meta } from "/builds/im2.0/aican/pages/index/setup/quickReply.vue?macro=true";
import { default as rolesMkDFwuY1lVMeta } from "/builds/im2.0/aican/pages/index/setup/roles.vue?macro=true";
import { default as satisfactionGcidO5WFJBMeta } from "/builds/im2.0/aican/pages/index/setup/satisfaction.vue?macro=true";
import { default as sensitivej1Z1LYtVC6Meta } from "/builds/im2.0/aican/pages/index/setup/sensitive.vue?macro=true";
import { default as teamB9IsW7h4rGMeta } from "/builds/im2.0/aican/pages/index/setup/team.vue?macro=true";
import { default as trustedDomain8zA9azBQ2KMeta } from "/builds/im2.0/aican/pages/index/setup/trustedDomain.vue?macro=true";
import { default as setup1VfRHp854zMeta } from "/builds/im2.0/aican/pages/index/setup/webAccess/setup.vue?macro=true";
import { default as webf1xtRpxPEHMeta } from "/builds/im2.0/aican/pages/index/setup/webAccess/web.vue?macro=true";
import { default as webAccessnKLaIKuUwJMeta } from "/builds/im2.0/aican/pages/index/setup/webAccess.vue?macro=true";
import { default as setupqj0Q4ZsrwTMeta } from "/builds/im2.0/aican/pages/index/setup.vue?macro=true";
import { default as index8SVXh79VPfMeta } from "/builds/im2.0/aican/pages/index.vue?macro=true";
import { default as inviteUserVerifydRDE0mhyZfMeta } from "/builds/im2.0/aican/pages/inviteUserVerify.vue?macro=true";
import { default as loginLafoa2PoNpMeta } from "/builds/im2.0/aican/pages/login.vue?macro=true";
import { default as registerNg0wPncrM5Meta } from "/builds/im2.0/aican/pages/register.vue?macro=true";
import { default as registerVerifyo0OAWshWWSMeta } from "/builds/im2.0/aican/pages/registerVerify.vue?macro=true";
import { default as resetPwd0a6hzjK3C6Meta } from "/builds/im2.0/aican/pages/resetPwd.vue?macro=true";
import { default as verifyjnbmQTn9vhMeta } from "/builds/im2.0/aican/pages/verify.vue?macro=true";
export default [
  {
    name: _91channelUrl_93PaWlbsOAmoMeta?.name ?? "chat-channelUrl",
    path: _91channelUrl_93PaWlbsOAmoMeta?.path ?? "/chat/:channelUrl()",
    meta: _91channelUrl_93PaWlbsOAmoMeta || {},
    alias: _91channelUrl_93PaWlbsOAmoMeta?.alias || [],
    redirect: _91channelUrl_93PaWlbsOAmoMeta?.redirect || undefined,
    component: () => import("/builds/im2.0/aican/pages/chat/[channelUrl].vue").then(m => m.default || m)
  },
  {
    name: _403DifnDE22aeMeta?.name ?? "errorPage-403",
    path: _403DifnDE22aeMeta?.path ?? "/errorPage/403",
    meta: _403DifnDE22aeMeta || {},
    alias: _403DifnDE22aeMeta?.alias || [],
    redirect: _403DifnDE22aeMeta?.redirect || undefined,
    component: () => import("/builds/im2.0/aican/pages/errorPage/403.vue").then(m => m.default || m)
  },
  {
    name: _404EtPRYamu3zMeta?.name ?? "errorPage-404",
    path: _404EtPRYamu3zMeta?.path ?? "/errorPage/404",
    meta: _404EtPRYamu3zMeta || {},
    alias: _404EtPRYamu3zMeta?.alias || [],
    redirect: _404EtPRYamu3zMeta?.redirect || undefined,
    component: () => import("/builds/im2.0/aican/pages/errorPage/404.vue").then(m => m.default || m)
  },
  {
    name: forgetPwdFmaapHtMuNMeta?.name ?? "forgetPwd",
    path: forgetPwdFmaapHtMuNMeta?.path ?? "/forgetPwd",
    meta: forgetPwdFmaapHtMuNMeta || {},
    alias: forgetPwdFmaapHtMuNMeta?.alias || [],
    redirect: forgetPwdFmaapHtMuNMeta?.redirect || undefined,
    component: () => import("/builds/im2.0/aican/pages/forgetPwd.vue").then(m => m.default || m)
  },
  {
    name: index8SVXh79VPfMeta?.name ?? "index",
    path: index8SVXh79VPfMeta?.path ?? "/",
    children: [
  {
    name: conversationd5B72YIctPMeta?.name ?? "index-conversation",
    path: conversationd5B72YIctPMeta?.path ?? "conversation",
    meta: conversationd5B72YIctPMeta || {},
    alias: conversationd5B72YIctPMeta?.alias || [],
    redirect: conversationd5B72YIctPMeta?.redirect || undefined,
    component: () => import("/builds/im2.0/aican/pages/index/conversation.vue").then(m => m.default || m)
  },
  {
    name: customerCenterBAMuPcySfzMeta?.name ?? "index-customerCenter",
    path: customerCenterBAMuPcySfzMeta?.path ?? "customerCenter",
    meta: customerCenterBAMuPcySfzMeta || {},
    alias: customerCenterBAMuPcySfzMeta?.alias || [],
    redirect: customerCenterBAMuPcySfzMeta?.redirect || undefined,
    component: () => import("/builds/im2.0/aican/pages/index/customerCenter.vue").then(m => m.default || m)
  },
  {
    name: feedbackGhEQLGtRzyMeta?.name ?? "index-feedback",
    path: feedbackGhEQLGtRzyMeta?.path ?? "feedback",
    meta: feedbackGhEQLGtRzyMeta || {},
    alias: feedbackGhEQLGtRzyMeta?.alias || [],
    redirect: feedbackGhEQLGtRzyMeta?.redirect || undefined,
    component: () => import("/builds/im2.0/aican/pages/index/feedback.vue").then(m => m.default || m)
  },
  {
    name: historyhaHMiGXUYQMeta?.name ?? "index-history",
    path: historyhaHMiGXUYQMeta?.path ?? "history",
    meta: historyhaHMiGXUYQMeta || {},
    alias: historyhaHMiGXUYQMeta?.alias || [],
    redirect: historyhaHMiGXUYQMeta?.redirect || undefined,
    component: () => import("/builds/im2.0/aican/pages/index/history.vue").then(m => m.default || m)
  },
  {
    name: homeYyQLCzOL84Meta?.name ?? "index-home",
    path: homeYyQLCzOL84Meta?.path ?? "home",
    meta: homeYyQLCzOL84Meta || {},
    alias: homeYyQLCzOL84Meta?.alias || [],
    redirect: homeYyQLCzOL84Meta?.redirect || undefined,
    component: () => import("/builds/im2.0/aican/pages/index/home.vue").then(m => m.default || m)
  },
  {
    name: profileG6dyZ4E2jaMeta?.name ?? "index-profile",
    path: profileG6dyZ4E2jaMeta?.path ?? "profile",
    meta: profileG6dyZ4E2jaMeta || {},
    alias: profileG6dyZ4E2jaMeta?.alias || [],
    redirect: profileG6dyZ4E2jaMeta?.redirect || undefined,
    component: () => import("/builds/im2.0/aican/pages/index/profile.vue").then(m => m.default || m)
  },
  {
    name: setupqj0Q4ZsrwTMeta?.name ?? "index-setup",
    path: setupqj0Q4ZsrwTMeta?.path ?? "setup",
    children: [
  {
    name: assignoGWt5i2QJ8Meta?.name ?? "index-setup-assign",
    path: assignoGWt5i2QJ8Meta?.path ?? "assign",
    meta: assignoGWt5i2QJ8Meta || {},
    alias: assignoGWt5i2QJ8Meta?.alias || [],
    redirect: assignoGWt5i2QJ8Meta?.redirect || undefined,
    component: () => import("/builds/im2.0/aican/pages/index/setup/assign.vue").then(m => m.default || m)
  },
  {
    name: basicZTYi8Y1XjEMeta?.name ?? "index-setup-basic",
    path: basicZTYi8Y1XjEMeta?.path ?? "basic",
    meta: basicZTYi8Y1XjEMeta || {},
    alias: basicZTYi8Y1XjEMeta?.alias || [],
    redirect: basicZTYi8Y1XjEMeta?.redirect || undefined,
    component: () => import("/builds/im2.0/aican/pages/index/setup/basic.vue").then(m => m.default || m)
  },
  {
    name: blacklistNtcuQKhEEUMeta?.name ?? "index-setup-blacklist",
    path: blacklistNtcuQKhEEUMeta?.path ?? "blacklist",
    meta: blacklistNtcuQKhEEUMeta || {},
    alias: blacklistNtcuQKhEEUMeta?.alias || [],
    redirect: blacklistNtcuQKhEEUMeta?.redirect || undefined,
    component: () => import("/builds/im2.0/aican/pages/index/setup/blacklist.vue").then(m => m.default || m)
  },
  {
    name: conversationTag6ccwzCvafGMeta?.name ?? "index-setup-conversationTag",
    path: conversationTag6ccwzCvafGMeta?.path ?? "conversationTag",
    meta: conversationTag6ccwzCvafGMeta || {},
    alias: conversationTag6ccwzCvafGMeta?.alias || [],
    redirect: conversationTag6ccwzCvafGMeta?.redirect || undefined,
    component: () => import("/builds/im2.0/aican/pages/index/setup/conversationTag.vue").then(m => m.default || m)
  },
  {
    name: customerTagEKsj6m05O5Meta?.name ?? "index-setup-customerTag",
    path: customerTagEKsj6m05O5Meta?.path ?? "customerTag",
    meta: customerTagEKsj6m05O5Meta || {},
    alias: customerTagEKsj6m05O5Meta?.alias || [],
    redirect: customerTagEKsj6m05O5Meta?.redirect || undefined,
    component: () => import("/builds/im2.0/aican/pages/index/setup/customerTag.vue").then(m => m.default || m)
  },
  {
    name: queueZMmRiZnHJSMeta?.name ?? "index-setup-queue",
    path: queueZMmRiZnHJSMeta?.path ?? "queue",
    meta: queueZMmRiZnHJSMeta || {},
    alias: queueZMmRiZnHJSMeta?.alias || [],
    redirect: queueZMmRiZnHJSMeta?.redirect || undefined,
    component: () => import("/builds/im2.0/aican/pages/index/setup/queue.vue").then(m => m.default || m)
  },
  {
    name: quickReplyINA2Ha6960Meta?.name ?? "index-setup-quickReply",
    path: quickReplyINA2Ha6960Meta?.path ?? "quickReply",
    meta: quickReplyINA2Ha6960Meta || {},
    alias: quickReplyINA2Ha6960Meta?.alias || [],
    redirect: quickReplyINA2Ha6960Meta?.redirect || undefined,
    component: () => import("/builds/im2.0/aican/pages/index/setup/quickReply.vue").then(m => m.default || m)
  },
  {
    name: rolesMkDFwuY1lVMeta?.name ?? "index-setup-roles",
    path: rolesMkDFwuY1lVMeta?.path ?? "roles",
    meta: rolesMkDFwuY1lVMeta || {},
    alias: rolesMkDFwuY1lVMeta?.alias || [],
    redirect: rolesMkDFwuY1lVMeta?.redirect || undefined,
    component: () => import("/builds/im2.0/aican/pages/index/setup/roles.vue").then(m => m.default || m)
  },
  {
    name: satisfactionGcidO5WFJBMeta?.name ?? "index-setup-satisfaction",
    path: satisfactionGcidO5WFJBMeta?.path ?? "satisfaction",
    meta: satisfactionGcidO5WFJBMeta || {},
    alias: satisfactionGcidO5WFJBMeta?.alias || [],
    redirect: satisfactionGcidO5WFJBMeta?.redirect || undefined,
    component: () => import("/builds/im2.0/aican/pages/index/setup/satisfaction.vue").then(m => m.default || m)
  },
  {
    name: sensitivej1Z1LYtVC6Meta?.name ?? "index-setup-sensitive",
    path: sensitivej1Z1LYtVC6Meta?.path ?? "sensitive",
    meta: sensitivej1Z1LYtVC6Meta || {},
    alias: sensitivej1Z1LYtVC6Meta?.alias || [],
    redirect: sensitivej1Z1LYtVC6Meta?.redirect || undefined,
    component: () => import("/builds/im2.0/aican/pages/index/setup/sensitive.vue").then(m => m.default || m)
  },
  {
    name: teamB9IsW7h4rGMeta?.name ?? "index-setup-team",
    path: teamB9IsW7h4rGMeta?.path ?? "team",
    meta: teamB9IsW7h4rGMeta || {},
    alias: teamB9IsW7h4rGMeta?.alias || [],
    redirect: teamB9IsW7h4rGMeta?.redirect || undefined,
    component: () => import("/builds/im2.0/aican/pages/index/setup/team.vue").then(m => m.default || m)
  },
  {
    name: trustedDomain8zA9azBQ2KMeta?.name ?? "index-setup-trustedDomain",
    path: trustedDomain8zA9azBQ2KMeta?.path ?? "trustedDomain",
    meta: trustedDomain8zA9azBQ2KMeta || {},
    alias: trustedDomain8zA9azBQ2KMeta?.alias || [],
    redirect: trustedDomain8zA9azBQ2KMeta?.redirect || undefined,
    component: () => import("/builds/im2.0/aican/pages/index/setup/trustedDomain.vue").then(m => m.default || m)
  },
  {
    name: webAccessnKLaIKuUwJMeta?.name ?? "index-setup-webAccess",
    path: webAccessnKLaIKuUwJMeta?.path ?? "webAccess",
    children: [
  {
    name: setup1VfRHp854zMeta?.name ?? "index-setup-webAccess-setup",
    path: setup1VfRHp854zMeta?.path ?? "setup",
    meta: setup1VfRHp854zMeta || {},
    alias: setup1VfRHp854zMeta?.alias || [],
    redirect: setup1VfRHp854zMeta?.redirect || undefined,
    component: () => import("/builds/im2.0/aican/pages/index/setup/webAccess/setup.vue").then(m => m.default || m)
  },
  {
    name: webf1xtRpxPEHMeta?.name ?? "index-setup-webAccess-web",
    path: webf1xtRpxPEHMeta?.path ?? "web",
    meta: webf1xtRpxPEHMeta || {},
    alias: webf1xtRpxPEHMeta?.alias || [],
    redirect: webf1xtRpxPEHMeta?.redirect || undefined,
    component: () => import("/builds/im2.0/aican/pages/index/setup/webAccess/web.vue").then(m => m.default || m)
  }
],
    meta: webAccessnKLaIKuUwJMeta || {},
    alias: webAccessnKLaIKuUwJMeta?.alias || [],
    redirect: webAccessnKLaIKuUwJMeta?.redirect || undefined,
    component: () => import("/builds/im2.0/aican/pages/index/setup/webAccess.vue").then(m => m.default || m)
  }
],
    meta: setupqj0Q4ZsrwTMeta || {},
    alias: setupqj0Q4ZsrwTMeta?.alias || [],
    redirect: setupqj0Q4ZsrwTMeta?.redirect || undefined,
    component: () => import("/builds/im2.0/aican/pages/index/setup.vue").then(m => m.default || m)
  }
],
    meta: index8SVXh79VPfMeta || {},
    alias: index8SVXh79VPfMeta?.alias || [],
    redirect: index8SVXh79VPfMeta?.redirect || undefined,
    component: () => import("/builds/im2.0/aican/pages/index.vue").then(m => m.default || m)
  },
  {
    name: inviteUserVerifydRDE0mhyZfMeta?.name ?? "inviteUserVerify",
    path: inviteUserVerifydRDE0mhyZfMeta?.path ?? "/inviteUserVerify",
    meta: inviteUserVerifydRDE0mhyZfMeta || {},
    alias: inviteUserVerifydRDE0mhyZfMeta?.alias || [],
    redirect: inviteUserVerifydRDE0mhyZfMeta?.redirect || undefined,
    component: () => import("/builds/im2.0/aican/pages/inviteUserVerify.vue").then(m => m.default || m)
  },
  {
    name: loginLafoa2PoNpMeta?.name ?? "login",
    path: loginLafoa2PoNpMeta?.path ?? "/login",
    meta: loginLafoa2PoNpMeta || {},
    alias: loginLafoa2PoNpMeta?.alias || [],
    redirect: loginLafoa2PoNpMeta?.redirect || undefined,
    component: () => import("/builds/im2.0/aican/pages/login.vue").then(m => m.default || m)
  },
  {
    name: registerNg0wPncrM5Meta?.name ?? "register",
    path: registerNg0wPncrM5Meta?.path ?? "/register",
    meta: registerNg0wPncrM5Meta || {},
    alias: registerNg0wPncrM5Meta?.alias || [],
    redirect: registerNg0wPncrM5Meta?.redirect || undefined,
    component: () => import("/builds/im2.0/aican/pages/register.vue").then(m => m.default || m)
  },
  {
    name: registerVerifyo0OAWshWWSMeta?.name ?? "registerVerify",
    path: registerVerifyo0OAWshWWSMeta?.path ?? "/registerVerify",
    meta: registerVerifyo0OAWshWWSMeta || {},
    alias: registerVerifyo0OAWshWWSMeta?.alias || [],
    redirect: registerVerifyo0OAWshWWSMeta?.redirect || undefined,
    component: () => import("/builds/im2.0/aican/pages/registerVerify.vue").then(m => m.default || m)
  },
  {
    name: resetPwd0a6hzjK3C6Meta?.name ?? "resetPwd",
    path: resetPwd0a6hzjK3C6Meta?.path ?? "/resetPwd",
    meta: resetPwd0a6hzjK3C6Meta || {},
    alias: resetPwd0a6hzjK3C6Meta?.alias || [],
    redirect: resetPwd0a6hzjK3C6Meta?.redirect || undefined,
    component: () => import("/builds/im2.0/aican/pages/resetPwd.vue").then(m => m.default || m)
  },
  {
    name: verifyjnbmQTn9vhMeta?.name ?? "verify",
    path: verifyjnbmQTn9vhMeta?.path ?? "/verify",
    meta: verifyjnbmQTn9vhMeta || {},
    alias: verifyjnbmQTn9vhMeta?.alias || [],
    redirect: verifyjnbmQTn9vhMeta?.redirect || undefined,
    component: () => import("/builds/im2.0/aican/pages/verify.vue").then(m => m.default || m)
  }
]